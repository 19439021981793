import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

// Async thunk to handle placing an order
export const placeOrder = createAsyncThunk(
  'order/placeOrder',
  async ({ restaurantId, paymentMethod, deliveryAddress, isRecurringOrder, recurringFrequency }, { rejectWithValue }) => {
    
    try {
      // Set recurringFrequency to null if it's empty
      const frequency = recurringFrequency ? recurringFrequency : null;

      const response = await axios.post(`${apiUrl}/order/place-order`, {
        restaurantId,
        paymentMethod,
        deliveryAddress,
        isRecurringOrder,
        recurringFrequency: frequency
      });
      
      return response.data.order;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to place order');
    }
  }
);


// Async thunk to get all orders placed by the user
export const getUserOrders = createAsyncThunk(
  'order/getUserOrders',
  async (restaurantId, { rejectWithValue }) => {
    console.log(restaurantId)
    try {
      const response = await axios.get(`${apiUrl}/order/get/${restaurantId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user orders');
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    currentOrder: null,
    userOrders: {
      regularOrders: [],
      recurringOrders: [],
    },
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(placeOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentOrder = action.payload;
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getUserOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserOrders.fulfilled, (state, action) => {
        console.log(action.payload)
        state.status = 'succeeded';
        state.userOrders = action.payload;
      })
      .addCase(getUserOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default orderSlice.reducer;