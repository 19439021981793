import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDeliveryAddresses,
  addDeliveryAddress,
  editDeliveryAddress,
  removeDeliveryAddress,
} from '../store/slices/authSlice';
import { placeOrder } from '../store/slices/orderSlice';
import Cookies from 'js-cookie';
import './checkout.css';
import { fetchCart } from '../store/slices/cartSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItems = useSelector((state) => state.cart.items);
  // const totalPrice = useSelector((state) => state.cart.totalPrice);
  const email = useSelector((state) => state.auth.email);
  const phoneNumberFromState = useSelector((state) => state.auth.phoneNumber);
  const deliveryAddresses = useSelector((state) => state.auth.deliveryAddresses);

  const [selectedAddress, setSelectedAddress] = useState('');
  const [newAddress, setNewAddress] = useState({
    label: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  // const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [shippingMethod, setShippingMethod] = useState('Local delivery');
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberFromState || '');
  const [deliveryInstructions, setDeliveryInstructions] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Cash on Delivery (COD)');
  const [showOptionsForAddress, setShowOptionsForAddress] = useState(null);
  const [isRecurringOrder, setIsRecurringOrder] = useState(false);
  const [recurringFrequency, setRecurringFrequency] = useState('');

  const restaurantId = Cookies.get('restaurantId');

  useEffect(() => {
    if (restaurantId) {
      dispatch(fetchCart(restaurantId));
      dispatch(fetchDeliveryAddresses(restaurantId));
    }
  }, [dispatch, restaurantId]);

  useEffect(() => {
    if (deliveryAddresses.length > 0) {
      setSelectedAddress(deliveryAddresses[0]._id);
    }
  }, [deliveryAddresses]);

  const calculateTotal = () => {
    const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const shipping = 15;
    return subtotal + shipping;
  };

  const handleAddAddress = () => {
    if (newAddress.label && newAddress.streetAddress && newAddress.city && newAddress.postalCode && newAddress.country) {
      dispatch(addDeliveryAddress({ restaurantId, address: newAddress })).then(() => {
        setIsAddModalVisible(false);
        setNewAddress({
          label: '',
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        });
      });
    }
  };

  const handleEditAddressSave = () => {
    dispatch(editDeliveryAddress({ restaurantId, addressId: newAddress._id, newAddress })).then(() => {
      setIsEditModalVisible(false);
      setNewAddress({
        label: '',
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      });
    });
  };

  const handleEditAddress = (addressId) => {
    const addressToEdit = deliveryAddresses.find((address) => address._id === addressId);
    setNewAddress(addressToEdit);
    setIsEditModalVisible(true);
  };

  const handleRemoveAddress = (addressId) => {
    dispatch(removeDeliveryAddress({ restaurantId, addressId }));
  };

  const handlePlaceOrder = () => {
    if (!selectedAddress) {
      toast.error('Error adding item. Please try again.');
      return;
    }

    dispatch(
      placeOrder({
        restaurantId,
        paymentMethod,
        deliveryAddress: selectedAddress,
        isRecurringOrder,
        recurringFrequency,
      })
    ).then((result) => {
      if (placeOrder.fulfilled.match(result)) {
        toast.success('Your order has been placed.');
        navigate('/order-confirmation');
      } else {
        toast.error('There was an error. Try again.');
      }
    });
  };

  return (
    <div className="checkout-page">
      <div className="checkout-left">
        <h2>Account</h2>
        <p>{email}</p>
        <div className="divider"></div>

        <h2>Ship to</h2>
        <div className="address-dropdown">
          <div className="selected-address" onClick={() => setIsAddingAddress(!isAddingAddress)}>
            {selectedAddress
              ? deliveryAddresses.find((address) => address._id === selectedAddress)?.label
              : deliveryAddresses.length > 0
              ? `${deliveryAddresses[0].label}: ${deliveryAddresses[0].streetAddress}, ${deliveryAddresses[0].city}, ${deliveryAddresses[0].state}, ${deliveryAddresses[0].postalCode}, ${deliveryAddresses[0].country}`
              : 'No Address Available'}
          </div>

          {isAddingAddress && (
            <div className="address-dropdown-content">
              {deliveryAddresses.length > 0 && (
                <div className="existing-addresses">
                  {deliveryAddresses.map((address) => (
                    <div key={address._id} className="address-item">
                      <div className="address-item-main">
                        <input
                          type="radio"
                          id={address._id}
                          name="address"
                          value={address._id}
                          checked={selectedAddress === address._id}
                          onChange={() => setSelectedAddress(address._id)}
                        />
                        <label htmlFor={address._id}>
                          {`${address.label}: ${address.streetAddress}, ${address.city}, ${address.state}, ${address.postalCode}, ${address.country}`}
                        </label>
                      </div>
                      <div className="three-dot-menu">
                        <span className="three-dots" onClick={() => setShowOptionsForAddress(showOptionsForAddress === address._id ? null : address._id)}>
                          &#x22EE;
                        </span>
                        {showOptionsForAddress === address._id && (
                          <div className="three-dot-dropdown">
                            <span className="dropdown-item" onClick={() => handleEditAddress(address._id)}>Edit</span>
                            <span className="dropdown-item delete-button" onClick={() => handleRemoveAddress(address._id)}>
                              Delete
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <span className="use-different-address" onClick={() => setIsAddModalVisible(true)}>
                + Use a different address
              </span>
            </div>
          )}
        </div>
        <div className="divider"></div>

        <div className="shipping-method">
          <h2>Shipping method</h2>
          <div className="shipping-option">
            <input
              type="radio"
              id="local-delivery"
              name="shippingMethod"
              value="Local delivery"
              checked={shippingMethod === 'Local delivery'}
              onChange={(e) => setShippingMethod(e.target.value)}
            />
            <label htmlFor="local-delivery">
              <span className="shipping-text">Local Delivery</span> <strong>· Free</strong>
            </label>
          </div>

          <div className="contact-number">
            <label htmlFor="phoneNumber" className="contact-number-label">Contact number</label>
            <input
              type="text"
              id="phoneNumber"
              value={"+" + 974 + phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Contact Number"
            />
          </div>

          <button className="add-delivery-instructions" onClick={() => setDeliveryInstructions('')}>
            Add delivery instructions
          </button>
          {deliveryInstructions && (
            <textarea
              value={deliveryInstructions}
              onChange={(e) => setDeliveryInstructions(e.target.value)}
              placeholder="Delivery instructions"
            />
          )}
        </div>
        <div className="divider"></div>

        <h2>Payment</h2>
        <div className="payment-method">
          <p>All transactions are secure and encrypted.</p>
          <div className="payment-option">
            <input
              type="radio"
              id="cod"
              name="paymentMethod"
              value="Cash on Delivery (COD)"
              checked={paymentMethod === 'Cash on Delivery (COD)'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <label htmlFor="cod">Cash on Delivery (COD)</label>
          </div>
        </div>

        <h2>Recurring Order</h2>
        <div className="recurring-order">
          <input
            type="checkbox"
            id="recurring-order"
            checked={isRecurringOrder}
            onChange={(e) => setIsRecurringOrder(e.target.checked)}
          />
          <label htmlFor="recurring-order">Recurring Order</label>
          {isRecurringOrder && (
            <div className="recurring-frequency">
              <label htmlFor="recurring-frequency">Frequency:</label>
              <select
                id="recurring-frequency"
                value={recurringFrequency}
                onChange={(e) => setRecurringFrequency(e.target.value)}
              >
                <option value="">Select Frequency</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          )}
        </div>

        <button className="complete-order-button" onClick={handlePlaceOrder}>
          Complete order
        </button>
      </div>

      <div className="checkout-right">
        <h3>Summary</h3>
        {cartItems.map((item) => (
          <div key={item._id} className="summary-item">
            <img src={item.product.media} alt={item.name} className="summary-item-image" />
            <div className="summary-item-details">
              <h4>{item.product.name}</h4>
              <p>
                {item.country} / {item.packagingWeight} / {item.type}
              </p>
              <p class="summary-price">QAR {item.price.toFixed(2)}</p>
            <p class="summary-quantity">Quantity: {item.quantity}</p>
            </div>
          </div>
        ))}
        <div className="summary-total">
          <p>Subtotal: QAR {cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2)}</p>
          <p>Shipping: QAR 15.00</p>
          <p>Total: QAR {calculateTotal().toFixed(2)}</p>
        </div>
      </div>

      {/* Modal for Adding Address */}
      {isAddModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close" onClick={() => setIsAddModalVisible(false)}>
              &times;
            </button>
            <h3>Add New Address</h3>
            <label>Label (e.g., Home, Office)</label>
            <input type="text" placeholder="Label (e.g., Home, Office)" value={newAddress.label} onChange={(e) => setNewAddress({ ...newAddress, label: e.target.value })} />
            <label>Street Address</label>
            <input type="text" placeholder="Street Address" value={newAddress.streetAddress} onChange={(e) => setNewAddress({ ...newAddress, streetAddress: e.target.value })} />
            <label>City</label>
            <input type="text" placeholder="City" value={newAddress.city} onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })} />
            <label>State</label>
            <input type="text" placeholder="State" value={newAddress.state} onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })} />
            <label>Postal Code</label>
            <input type="text" placeholder="Postal Code" value={newAddress.postalCode} onChange={(e) => setNewAddress({ ...newAddress, postalCode: e.target.value })} />
            <label>Country</label>
            <input type="text" placeholder="Country" value={newAddress.country} onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })} />
            <button className="complete-order-button" onClick={handleAddAddress}>Add Address</button>
          </div>
        </div>
      )}

      {/* Modal for Editing Address */}
      {isEditModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <button className="modal-close" onClick={() => setIsEditModalVisible(false)}>
              &times;
            </button>
            <h3>Edit Address</h3>
            <label>Label (e.g., Home, Office)</label>
            <input type="text" placeholder="Label (e.g., Home, Office)" value={newAddress.label} onChange={(e) => setNewAddress({ ...newAddress, label: e.target.value })} />
            <label>Street Address</label>
            <input type="text" placeholder="Street Address" value={newAddress.streetAddress} onChange={(e) => setNewAddress({ ...newAddress, streetAddress: e.target.value })} />
            <label>City</label>
            <input type="text" placeholder="City" value={newAddress.city} onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })} />
            <label>State</label>
            <input type="text" placeholder="State" value={newAddress.state} onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })} />
            <label>Postal Code</label>
            <input type="text" placeholder="Postal Code" value={newAddress.postalCode} onChange={(e) => setNewAddress({ ...newAddress, postalCode: e.target.value })} />
            <label>Country</label>
            <input type="text" placeholder="Country" value={newAddress.country} onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })} />
            <button className="complete-order-button" onClick={handleEditAddressSave}>Save Address</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;