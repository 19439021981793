import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addToCartBackend } from '../store/slices/cartSlice';
import { fetchProductById } from '../store/slices/productSlice';
import { useParams } from 'react-router-dom';
import './productDetails.css';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import LoadingModal from './loadingmodal';

const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const productData = useSelector((state) => state.products.selectedProduct);
  const status = useSelector((state) => state.products.status);
  const error = useSelector((state) => state.products.error);

  const product = productData?.product;
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedPackaging, setSelectedPackaging] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (id) {
      dispatch(fetchProductById(id));
    }
  }, [dispatch, id]);

  // Initialize selections when product data is loaded
  useEffect(() => {
    if (product && product.variants.length > 0) {
      const firstVariant = product.variants[0];
      setSelectedCountry(firstVariant.countryOrigin);
      if (firstVariant.priceDetails.length > 0) {
        setSelectedPackaging(firstVariant.priceDetails[0].packagingWeight);
        setSelectedType(firstVariant.priceDetails[0].type);
      }
    }
  }, [product]);

  const restaurantId = Cookies.get('restaurantId');

  const handleAddToCart = () => {
    if (product && selectedCountry && selectedPackaging && selectedType) {
      // Find the variant and priceDetail matching the selections
      const variant = product.variants.find(
        (variant) => variant.countryOrigin === selectedCountry && variant.priceDetails[0].packagingWeight === selectedPackaging
      );
      
      // Find the index of the selected variant
      const variantIndex = product.variants.findIndex(
        (variant) => variant.countryOrigin === selectedCountry && variant.priceDetails[0].packagingWeight === selectedPackaging
      );
      
      const priceDetail = variant?.priceDetails.find(
        (detail) =>
          detail.packagingWeight === selectedPackaging &&
        detail.type === selectedType
      );

      const cartItem = {
        restaurantId,
        productId: product._id,
        name: product.name,
        imageUrl: variant?.media[0] || product.media[0],
        countryOrigin: selectedCountry,
        packagingWeight: selectedPackaging,
        type: selectedType,
        price: priceDetail?.price || 'N/A',
        quantity,
        variantIndex // Added variantIndex here
      };
  
      dispatch(addToCartBackend(cartItem))
        .then(() => toast.success('Item added to cart!'))
        .catch(() => toast.error('Error adding item. Please try again.'));
    } else {
      alert('Please select valid options.');
    }
  };

  // Get unique country options
  const countryOptions = [...new Set(product?.variants.map((v) => v.countryOrigin))];

  // Get packaging options for selected country
  const packagingOptions = selectedCountry
    ? [
        ...new Set(
          product.variants
            .filter((v) => v.countryOrigin === selectedCountry)
            .flatMap((v) => v.priceDetails.map((pd) => pd.packagingWeight))
        ),
      ]
    : [];

  // Get type options for selected country and packaging
  const typeOptions =
    selectedCountry && selectedPackaging
      ? [
          ...new Set(
            product.variants
              .filter((v) => v.countryOrigin === selectedCountry)
              .flatMap((v) =>
                v.priceDetails
                  .filter((pd) => pd.packagingWeight === selectedPackaging)
                  .map((pd) => pd.type)
              )
          ),
        ]
      : [];

  // Get price based on selections
  const price =
    selectedCountry && selectedPackaging && selectedType
      ? product.variants
          .filter((v) => v.countryOrigin === selectedCountry)
          .flatMap((v) =>
            v.priceDetails.filter(
              (pd) =>
                pd.packagingWeight === selectedPackaging &&
                pd.type === selectedType
            )
          )[0]?.price || 'N/A'
      : 'N/A';

  if (status === 'loading') {
    return <LoadingModal />;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return product ? (
    <div className="product-container">
      <div className="product-details">
        <div className="product-images">
          <img src={product.media[0]} alt={product.name} className="main-image" />
        </div>

        <div className="product-info">
          <h1>{product.name}</h1>
          <p>{product.description}</p>

          {/* Country Selection */}
          <div className="product-attribute">
            <p>Country of Origin</p>
            <div className="attribute-options">
              {countryOptions.map((country) => (
                <button
                  key={country}
                  className={`option-button ${selectedCountry === country ? 'active' : ''}`}
                  onClick={() => {
                    setSelectedCountry(country);
                    setSelectedPackaging('');
                    setSelectedType('');
                  }}
                >
                  {country}
                </button>
              ))}
            </div>
          </div>

          {/* Packaging Selection */}
          {selectedCountry && (
            <div className="product-attribute">
              <p>Packaging</p>
              <div className="attribute-options">
                {packagingOptions.map((packaging) => (
                  <button
                    key={packaging}
                    className={`option-button ${
                      selectedPackaging === packaging ? 'active' : ''
                    }`}
                    onClick={() => {
                      setSelectedPackaging(packaging);
                      setSelectedType('');
                    }}
                  >
                    {packaging}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Type Selection */}
          {selectedPackaging && (
            <div className="product-attribute">
              <p>Type</p>
              <div className="attribute-options">
                {typeOptions.map((type) => (
                  <button
                    key={type}
                    className={`option-button ${selectedType === type ? 'active' : ''}`}
                    onClick={() => setSelectedType(type)}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Price Display */}
          {selectedType && (
            <div className="product-price">
              <h2>Price: QAR {price}</h2>
            </div>
          )}

          {/* Quantity Control */}
          <div className="quantity-control">
            <p>Quantity</p>
            <div className="quantity-buttons">
              <button onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}>-</button>
              <span>{quantity}</span>
              <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
            </div>
          </div>

          {/* Add to Cart Button */}
          <div className="product-actions">
            <button className="add-to-cart" onClick={handleAddToCart}>
              Add to cart
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ProductDetails;
