import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CartDropdown from '../cartDropdown';
import './header.css';

const Header = () => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleCartDropdown = () => setIsCartOpen(!isCartOpen);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const cartItems = useSelector((state) => state.cart.items);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const hiddenCartPaths = ['/cart', '/checkout', '/order-confirmation'];

  return (
    <header className="header">
      {/* Mobile Hamburger Menu Button on the left */}
      <div className="menu-button" onClick={toggleMenu}>
        ☰
      </div>

      <div className="logo">
        <img src="/Asset1@4x.png" alt="Agricope logo" className="logo-image" />
      </div>

      {/* Desktop Navigation */}
      <nav className="nav">
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/about" className="nav-link">About Us</Link>
        <Link to="/products" className="nav-link">Products</Link>
      </nav>

      {/* Mobile Dropdown Menu */}
      {menuOpen && (
        <nav className="mobile-nav">
          <Link to="/" className="nav-link" onClick={toggleMenu}>Home</Link>
          <Link to="/about" className="nav-link" onClick={toggleMenu}>About Us</Link>
          <Link to="/products" className="nav-link" onClick={toggleMenu}>Products</Link>
        </nav>
      )}

      <div className="header-right">
        <div className="icons">
          {/* <a href="#" className="icon">
            <img src="/search-icon.png" alt="Search" />
          </a> */}

          {isLoggedIn ? (
            <Link to="/profile" className="icon">
              <img src="/avatar-icon.png" alt="User" />
            </Link>
          ) : (
            <Link to="/login" className="icon">
              <img src="/avatar-icon.png" alt="User" />
            </Link>
          )}

          {isLoggedIn && !hiddenCartPaths.includes(location.pathname) && (
            <div className="icon cart-icon" onClick={toggleCartDropdown} style={{ position: 'relative' }}>
              <img src="/bag-icon.png" alt="Cart" />
              {cartItems.length > 0 && (
                <span className="cart-item-count">{cartItems.length}</span>
              )}
              {isCartOpen && <CartDropdown cartItems={cartItems} onClose={toggleCartDropdown} />}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
