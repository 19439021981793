import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element: Component }) => {
  const token = Cookies.get('token');
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isLoading = useSelector((state) => state.auth.isLoading);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while validation is ongoing
  }

  if (!token || !isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return Component;
};

export default ProtectedRoute;
