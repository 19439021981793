import { useEffect } from 'react';
import './orderConfirmation.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const OrderConfirmation = () => {
  const navigate = useNavigate();

  // Get the necessary data from the Redux store
  const email = useSelector((state) => state.auth.email);
  const phoneNumber = useSelector((state) => state.auth.phoneNumber);
  const cartItems = useSelector((state) => state.cart.items);
  const deliveryAddresses = useSelector((state) => state.auth.deliveryAddresses);
  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const selectedAddressId = useSelector((state) => state.order.selectedAddressId);
const order = useSelector((state)=> state.order.currentOrder)
  // Find the selected delivery address
  const selectedAddress = deliveryAddresses.find((address) => address._id === selectedAddressId);
console.log(order)
  const shippingAddress = selectedAddress
    ? `${selectedAddress.label}: ${selectedAddress.streetAddress}, ${selectedAddress.city}, ${selectedAddress.state}, ${selectedAddress.postalCode}, ${selectedAddress.country}`
    : 'No Address Selected';
  
  const billingAddress = shippingAddress; // Assuming billing is the same as shipping
  const shippingMethod = 'Local Delivery'; // Static value for this example
  
  const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const shipping = 15; // Fixed shipping cost
  const total = subtotal + shipping;

  // Redirect to the homepage if no order has been placed
  useEffect(() => {
    if (cartItems.length === 0) {
      navigate('/');
    }
  }, [cartItems, navigate]);

  return (
    <div className="order-confirmation-page">
      <div className="order-confirmation-left">
        <div className="confirmation-message">
          <h2>Order Number: {order.orderNumber}</h2>
          <h1>Thank you, {email.split('@')[0]}!</h1>
          <p>Your order is confirmed</p>
          <p>You'll receive a confirmation email with your order number shortly.</p>
          <p>Delivery information: Your order will be delivered in 12 - 24 hours</p>
        </div>

     

        <div className="order-details">
          <h3>Order details</h3>
          <div className="order-info">
            <p><strong>Contact information:</strong> {email}</p>
            <p><strong>Phone number:</strong> {phoneNumber}</p>
            <p><strong>Shipping address:</strong> {order.deliveryAddress.label}</p>
            <p><strong>Shipping method:</strong> {shippingMethod}</p>
            <p><strong>Payment method:</strong> {paymentMethod} - QAR {total.toFixed(2)}</p>
            <p><strong>Billing address:</strong> {billingAddress}</p>
          </div>
          <a href="#" className="view-account">View account</a>
        </div>

        <a href="#" className="contact-support">Need help? Contact us</a>
      </div>

      <div className="order-confirmation-right">
        <h3>Summary</h3>
        {order.items.map((item) => (
          <div key={item._id} className="summary-item">
            <img src={item.product.media} alt={item.product.name} className="summary-item-image" />
            <div className="summary-item-details">
              <h4>{item.product.name}</h4>
              <p>{item.country} / {item.packagingWeight} / {item.type}</p>
              <p>QAR {item.price.toFixed(2)}</p>
            </div>
            <p>Quantity: {item.quantity}</p>
          </div>
        ))}
        <div className="summary-total">
          <p>Subtotal: QAR {subtotal.toFixed(2)}</p>
          <p>Shipping: QAR {shipping.toFixed(2)}</p>
          <p><strong>Total: QAR {total.toFixed(2)}</strong></p>
        </div>
      </div>

    </div>
  );
};

export default OrderConfirmation;
