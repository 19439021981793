import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/store';
import { fetchProducts } from '../store/slices/productSlice';
import './products.css';
import LoadingModal from './loadingmodal';

const Products = () => {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.products.items);
  const status = useAppSelector((state) => state.products.status);
  const error = useAppSelector((state) => state.products.error);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState('default');
  const productsPerPage = 12;

  useEffect(() => {
    dispatch(fetchProducts()); // Dispatch action to fetch all products
  }, [dispatch]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  // const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Sorting logic
  const sortedProducts = [...products].sort((a, b) => {
    if (sortOption === 'price-low-high') {
      return a.minPrice - b.minPrice;
    }
    if (sortOption === 'price-high-low') {
      return b.minPrice - a.minPrice;
    }
    if (sortOption === 'alphabetical-asc') {
      return a.name.localeCompare(b.name);
    }
    if (sortOption === 'alphabetical-desc') {
      return b.name.localeCompare(a.name);
    }
    return 0; // Default sorting
  });

  const displayedProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  if (status === 'loading') {
    return <LoadingModal/>
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="products-page">
      <div className="products-header">
        <div className="sort-container">
          <label className="sort-label">
            Sort by:&nbsp;
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="sort-dropdown"
            >
              <option value="default">Default</option>
              <option value="price-low-high">Price: Low to High</option>
              <option value="price-high-low">Price: High to Low</option>
              <option value="alphabetical-asc">Alphabetically, A-Z</option>
              <option value="alphabetical-desc">Alphabetically, Z-A</option>
            </select>
          </label>
          <span className="product-count">{products.length} products</span>
        </div>
      </div>

      <div className="products-grid">
  {displayedProducts.map((product) => (
    <Link key={product._id} to={`/products/${product._id}`} className="product-link">
      <div className="product-card">
        <img src={product.media[0]} alt={product.name} className="product-image" />
        <h3 className="product-name">{product.name}</h3>
        <p className="product-price">From QAR {product.minPrice.toFixed(2)}</p>
      </div>
    </Link>
  ))}
</div>

      {/* Pagination */}
      {products.length > productsPerPage && (
        <div className="pagination">
          {Array.from({ length: Math.ceil(products.length / productsPerPage) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Products;
