import { useEffect } from 'react';
import './cart.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCart, updateCartItemQuantityBackend, removeFromCartBackend } from '../store/slices/cartSlice';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const restaurantId = Cookies.get('restaurantId');

  useEffect(() => {
    if (restaurantId) {
      dispatch(fetchCart(restaurantId));
    }
  }, [dispatch, restaurantId]);

  const handleIncreaseQuantity = (itemId) => {
    const item = cartItems.find((item) => item._id === itemId);
    if (item) {
      dispatch(updateCartItemQuantityBackend({ itemId, quantity: item.quantity + 1 }));
    }
  };

  const handleDecreaseQuantity = (itemId) => {
    const item = cartItems.find((item) => item._id === itemId);
    if (item && item.quantity > 1) {
      dispatch(updateCartItemQuantityBackend({ itemId, quantity: item.quantity - 1 }));
    }
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCartBackend(itemId));
  };

  const handleCheckoutClick = () => {
    if (cartItems.length === 0) {
      toast.error('Please add products to checkout.')
    }
  };

  return (
    <div className="cart-page">
      <ToastContainer />
      <h1>Your Cart</h1>
      <div className="cart-header">
        <span>PRODUCT</span>
        <span>QUANTITY</span>
        <span>TOTAL</span>
      </div>

      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        cartItems.map((item) => (
          <div key={item._id} className="cart-item">
            <div className="cart-item-info">
            <img src={item.product.media} alt={item.name} className="cart-item-image" />
              <div>
                <h3>{item.product.name}</h3>
                <p>QAR {(item.price || 0).toFixed(2)}</p>
                <p>Country: {item.country}</p>
                <p>Packaging: {item.packagingWeight}</p>
                <p>Type: {item.type}</p>
              </div>
            </div>
            <div className="cart-item-quantity">
              <button onClick={() => handleDecreaseQuantity(item._id)}>-</button>
              <span>{item.quantity}</span>
              <button onClick={() => handleIncreaseQuantity(item._id)}>+</button>
              <button className="remove-item" onClick={() => handleRemoveItem(item._id)}>🗑</button>
            </div>
            <div className="cart-item-total">
              QAR {((item.price || 0) * item.quantity).toFixed(2)}
            </div>
          </div>
        ))
      )}

      <div className="cart-summary">
        <h2>Estimated Total</h2>
        <p className="cart-summary-total">QAR {totalPrice.toFixed(2)}</p>
        <p>Taxes, discounts, and shipping calculated at checkout</p>
        <button
          className="checkout-button"
          onClick={handleCheckoutClick}
          // disabled={cartItems.length === 0}
        >
          {cartItems.length > 0 ? (
            <Link to="/checkout" className="checkout-link">Checkout</Link>
          ) : (
            'Checkout'
          )}
        </button>
      </div>

      {/* <a href="#" className="continue-shopping">Continue shopping</a> */}
    </div>
  );
};

export default Cart;
