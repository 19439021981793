import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-sections">
        <div className="footer-column">
          <h3 className="footer-title">Resources</h3>
          <ul className="footer-links">
            <li><a href="#">Guides</a></li>
            <li><a href="#">Blogs</a></li>
            <li><a href="#">Customer Stories</a></li>
            <li><a href="#">Glossary</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3 className="footer-title">Quick Links</h3>
          <ul className="footer-links">
            <li><a href="#">About Us</a></li>
            <li><a href="#">Partners</a></li>
            <li><a href="#">Contact Us</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3 className="footer-title">Contact Us</h3>
          <ul className="footer-links">
            <li>+974 3040 3038</li>
            <li><a href="mailto:info@agricope.com">info@agricope.com</a></li>
            <li>Al Nasr Tower B, Doha, Corniche</li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="region-selector">
          <span>Country/region</span>
          <div className="region-dropdown">
            <button className="region-button">Qatar | QAR ر.ق</button>
          </div>
        </div>
        <div className="copyright">
          © 2024, Agricope
        </div>
      </div>
    </footer>
  );
};

export default Footer;
