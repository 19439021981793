import './loadingModal.css';

const LoadingModal = () => {
  return (
    <div className="loading-modal">
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <div className="loading-text">Loading</div>
      </div>
    </div>
  );
};

export default LoadingModal;