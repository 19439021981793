import { useEffect,useState } from 'react';
import './home.css';

const faqs = [
  {
    question: "What is Agricope?",
    answer: "Agricope is a B2B e-commerce platform that directly connects farmers with food businesses in Qatar. We facilitate seamless transactions, ensuring fair prices for farmers and timely access to high-quality produce for food businesses."
  },
  {
    question: "Why can't I access the product page?",
    answer: "Agricope is only dealing with B2B business. If you are one of the businesses in Qatar, please mention your details down below."
  },
  {
    question: "How does Agricope benefit farmers?",
    answer: "Agricope empowers farmers by providing them with a broader market reach, increasing their profitability, and reducing dependence on middlemen. Through our platform, farmers can showcase their produce directly to a wider audience of food businesses."
  },
  {
    question: "What advantages do food businesses gain from using Agricope?",
    answer: "Food businesses benefit from Agricope by gaining access to fresh, high-quality produce without the hassle of traditional procurement processes. Our platform streamlines the supply chain, ensuring timely deliveries and fair pricing for food businesses."
  },
  {
    question: "How does Agricope handle logistics and deliveries for orders placed through the platform?",
    answer: "Agricope partners with reliable logistics and delivery services to ensure timely and efficient deliveries. We coordinate closely with farmers and food businesses to streamline the logistics process and minimize disruptions."
  },
  {
    question: "Any further questions?",
    answer: "If you haven't found the answer to your query in the above FAQs we have further information about Agricope available in our Help Center."
  },
];
const Home = () => {


  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the active state
  };
  useEffect(() => {
    const slider = document.querySelector('.slider');
    const truckImage = document.querySelector('.truck-image img');
    const produceImage = document.querySelector('.produce-image img');
    const container = document.querySelector('.slider-container');

    let isDragging = false;

    const handleMouseDown = () => {
      isDragging = true;
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const containerRect = container.getBoundingClientRect();
      let sliderPosition = e.clientX - containerRect.left;

      // Restrict slider within the bounds of the image
      if (sliderPosition < 0) sliderPosition = 0;
      if (sliderPosition > containerRect.width) sliderPosition = containerRect.width;

      const percentage = (sliderPosition / containerRect.width) * 100;

      slider.style.left = `${percentage}%`;
      truckImage.style.clipPath = `inset(0 ${100 - percentage}% 0 0)`;
      produceImage.style.clipPath = `inset(0 0 0 ${percentage}%)`;
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    slider.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    // Cleanup event listeners when the component unmounts
    return () => {
      slider.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Smart Sourcing, Simplified!</h1>
          <p>Connecting farms and food suppliers straight to food businesses.</p>
          <button className="contact-button">Contact us</button>
        </div>
        <div className="hero-image">
          <img src="/gempages_505393600742294634-8d382977-9114-4d41-bfad-f5df412aca69.webp" alt="Mobile App" />
        </div>
      </section>

      {/* Why Choose Agricope Section */}
      <section className="why-choose-section">
  <h2>Why choose Agricope?</h2>
  <div className="why-choose-cards">
    <div className="card">
      <div className="card-icon">
        <img src="supplierdiversity.avif" alt="Supplier Diversity Icon" />
      </div>
      <h3>Supplier Diversity</h3>
      <hr />
      <p>Agricope offers food businesses access to a wide variety of agricultural products, ensuring they can find the ingredients they need.</p>
    </div>
    <div className="card">
      <div className="card-icon">
        <img src="easyorderingprocess.avif" alt="Easy Ordering Process Icon" />
      </div>
      <h3>Easy Ordering Process</h3>
      <hr />
      <p>Food businesses can browse through a wide selection of fresh produce available on the platform and place orders easily.</p>
    </div>
    <div className="card">
      <div className="card-icon">
        <img src="digitalconvenience.avif" alt="Digital Convenience Icon" />
      </div>
      <h3>Digital Convenience</h3>
      <hr />
      <p>Our tech-driven approach simplifies sourcing and ordering processes, saving time and effort for both farmers and food businesses.</p>
    </div>
  </div>
</section>


      {/* What Makes Us Different Section */}
      <section className="difference-section">
      <h2>What makes us different?</h2>
      <div className="difference-content">
        <div className="difference-left">
          <div className="difference-item">
            <div className="icon">
              <img src="Directfarmconnection.svg" alt="Direct Farm Connection Icon" />
            </div>
            <div className="text">
              <h3>Direct Farm Connection</h3>
              <p>We facilitate direct connections between food businesses and local farmers.</p>
            </div>
          </div>
          <div className="difference-item">
            <div className="icon">
              <img src="customercentricity.webp" alt="Customer-Centricity Icon" />
            </div>
            <div className="text">
              <h3>Customer-Centricity</h3>
              <p>We tailor our services to help our customers/partners flourish in the food market.</p>
            </div>
          </div>
        </div>

        <div className="image-slider">
          <div className="slider-container">
            <div className="slider">
              <span className="slider-arrow left-arrow">←</span>
              <span className="slider-arrow right-arrow">→</span>
            </div>
            <div className="truck-image">
              <img src="truck.webp" alt="Truck Image" />
            </div>
            <div className="produce-image">
              <img src="crate.webp" alt="Produce Image" />
            </div>
          </div>
        </div>

        <div className="difference-right">
          <div className="difference-item">
            <div className="icon">
              <img src="Seamlessordering.png" alt="Seamless Ordering Icon" />
            </div>
            <div className="text">
              <h3>Seamless Ordering</h3>
              <p>Our platform allows businesses to easily order fresh produce online.</p>
            </div>
          </div>
          <div className="difference-item">
            <div className="icon">
              <img src="sustainability.webp" alt="Sustainability Icon" />
            </div>
            <div className="text">
              <h3>Sustainability</h3>
              <p>We empower local sourcing to reduce carbon footprints and support sustainability.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

      {/* Farmers and Food Businesses Benefits Section */}
      <section className="benefits-section">
        <div className="benefits-item">
          <img src="farmersbenefits.jpg" alt="Farmer" />
          <div className="benefits-content">
            <h2>Farmers Benefits</h2>
            <ul>
              <li>Increase Profitability</li>
              <li>Gain Wider Market Access</li>
              <li>Receive and Manage Orders</li>
            </ul>
            <button className="read-more-button">Read more</button>
          </div>
        </div>
        <div className="benefits-item">
          <div className="benefits-content">
            <h2>Food Businesses Benefits</h2>
            <ul>
              <li>Cost Effective Fresh Produces</li>
              <li>Streamlined Procurement</li>
              <li>Hassle-Free Delivery</li>
            </ul>
            <button className="read-more-button">Read more</button>
          </div>
          <img src="foodbusinessbenefits.webp" alt="Food Business" />
        </div>
      </section>

      {/* FAQ Section */}
      <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-card ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFaq(index)} // Toggle the active index
          >
            <div className="faq-header">
              <span>{faq.question}</span>
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div
              className="faq-answer"
              style={{
                maxHeight: activeIndex === index ? '300px' : '0', // Smooth transition based on active state
                opacity: activeIndex === index ? '1' : '0', // Smooth opacity transition
              }}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Home;