import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import About from './components/about';
import Login from './components/login';
import Cart from './components/cart';
import OrderConfirmation from './components/orderConfirmation';
import Checkout from './components/checkout';
import Products from './components/products';
import ProductDetails from './components/productDetails';
import Profile from './components/profile';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken } from './store/slices/authSlice';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import LoadingModal from './components/loadingmodal';
import UnderDevelopment from './components/underdevelopment';
import { fetchCart } from './store/slices/cartSlice';

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);

  // On app load, validate the token
  useEffect(() => {
    dispatch(validateToken())
      .then(() => {
        dispatch(fetchCart())
      })
      .catch(() => {
        toast.error('Your session has expired. Please login again.');
      });
  }, [dispatch]);
  if (isLoading) {
    return <LoadingModal/>; 
  }
  return (
    <Router>
      <div className="app-container">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/products" element={<Products />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/products/:id" element={<ProtectedRoute element={<ProductDetails />} />} />
            {/* Protected Routes */}
            <Route path="/cart" element={<ProtectedRoute element={<Cart />} />} />
            <Route path="/checkout" element={<ProtectedRoute element={<Checkout />} />} />
            <Route path="/order-confirmation" element={<ProtectedRoute element={<OrderConfirmation />} />} />
            <Route path="*" element={<UnderDevelopment />} />
          </Routes>
        </main>
        <Footer />
        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;
