import './underDevelopment.css';

const UnderDevelopment = () => {
  return (
    <div className="under-development">
      <div className="under-development-content">
        <h1>🚧 This Page is Being Developed 🚧</h1>
        <p>We are working hard to bring you this page. It will be available shortly. Thank you for your patience!</p>
        <p>Log out</p>
      </div>
    </div>
  );
};

export default UnderDevelopment;