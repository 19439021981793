import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
const apiUrl = process.env.REACT_APP_API_URL;

// Async thunk to handle fetching cart items from the backend
export const fetchCart = createAsyncThunk(
  'cart/fetchCart',
  async (restaurantId, { getState }) => {
    let id = Cookies.get('restaurantId', restaurantId);
    const state = getState();
    const token = state.auth.token;
    const response = await axios.get(`${apiUrl}/cart/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Async thunk to handle adding items to the cart in the backend
export const addToCartBackend = createAsyncThunk(
  'cart/addToCartBackend',
  async (cartItem, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    console.log(cartItem)
    const response = await axios.post(`${apiUrl}/cart/add-item`, cartItem, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Async thunk to handle quantity updates in the backend
export const updateCartItemQuantityBackend = createAsyncThunk(
  'cart/updateCartItemQuantityBackend',
  async ({ itemId, quantity }, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const restaurantId = state.auth.restaurantId;
    const response = await axios.post(
      `${apiUrl}/cart/update-quantity`,
      { restaurantId, itemId, quantity },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  }
);

// Async thunk to handle removing an item from the cart in the backend
export const removeFromCartBackend = createAsyncThunk(
  'cart/removeFromCartBackend',
  async (itemId, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const restaurantId = state.auth.restaurantId;

    const response = await axios.post(
      `${apiUrl}/cart/remove-item`,
      { restaurantId, itemId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    totalPrice: 0,
    status: 'idle',
    error: null,
  },
  reducers: {
    clearCart: (state) => {
      state.items = [];
      state.totalPrice = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.items = action.payload.items || [];
        state.totalPrice = action.payload.totalPrice || 0;
      })

      .addCase(addToCartBackend.fulfilled, (state, action) => {
        const { cart } = action.payload; // Assuming the entire cart is returned
        state.items = cart.items;
        state.totalPrice = cart.totalPrice;
      })

      .addCase(updateCartItemQuantityBackend.fulfilled, (state, action) => {
        const { cart } = action.payload; // Assuming the entire cart is returned
        state.items = cart.items;
        state.totalPrice = cart.totalPrice;
      })

      .addCase(removeFromCartBackend.fulfilled, (state, action) => {
        const { cart } = action.payload; // Assuming the entire cart is returned
        state.items = cart.items;
        state.totalPrice = cart.totalPrice;
      });
  },
});

export const { clearCart } = cartSlice.actions;
export default cartSlice.reducer;
