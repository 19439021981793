import './about.css'; // Import the CSS for the About page

const About = () => {
  return (
    <div className="about-page">
      <h1>About Us</h1>

      <section className="about-section">
        <h2>About Us</h2>
        <p>
          Welcome to Agricope, your trusted partner in reshaping Qatar's agricultural and food supply chain.
          Our mission is simple yet impactful: to create a seamless, efficient connection between local farms
          and food businesses.
        </p>
      </section>

      <section className="about-section">
        <h2>Who We Are</h2>
        <p>
          Agricope is dedicated to empowering local farmers by providing them with greater market reach and
          increased profitability. At the same time, we streamline the sourcing process for food businesses,
          ensuring they have easy access to high-quality, fresh produce.
        </p>
      </section>

      <section className="about-section">
        <h2>What We Do</h2>
        <p>
          We leverage innovative technology to simplify and modernize the way farms connect with food businesses.
          By cutting out unnecessary intermediaries, we make it easier for farmers to sell their products and for
          businesses to find the fresh ingredients they need. Our platform is designed to foster a more sustainable
          and interconnected agricultural community in Qatar.
        </p>
      </section>

      <section className="about-section">
        <h2>Our Vision</h2>
        <p>
          Agricope envisions a future where collaboration, simplicity, and fairness thrive in the agricultural and
          food business sectors. We are committed to promoting sustainability, supporting local farmers, and ensuring
          that food businesses have a reliable source of fresh, local produce.
        </p>
      </section>

      <section className="about-section">
        <h2>Why Choose Agricope</h2>
        <p>
          Choosing Agricope means choosing a partner who values quality, efficiency, and community. We are dedicated
          to making the supply chain more transparent and effective for everyone involved. Join us in creating a
          thriving, sustainable food ecosystem in Qatar.
        </p>
      </section>
    </div>
  );
};

export default About;