// src/store/slices/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
const apiUrl = process.env.REACT_APP_API_URL;


export const validateToken = createAsyncThunk(
  'auth/validateToken',
  async (_, { rejectWithValue }) => {
    const token = Cookies.get('token');
    if (!token) {
      return rejectWithValue('No token found');
    }

    try {
      const response = await axios.get(`${apiUrl}/auth/validate-token`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data; // Assuming response includes user data if the token is valid
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to handle login
export const loginUser = createAsyncThunk('auth/loginUser', async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${apiUrl}/auth/login`, { email, password });
    const { token, restaurantId, name, phoneNumber, email: userEmail } = response.data.value;

    // Save token and restaurant ID to cookies
    Cookies.set('token', token, { expires: 7 });
    Cookies.set('restaurantId', restaurantId);
    Cookies.set('name', name);
    Cookies.set('phoneNumber', phoneNumber);
    Cookies.set('email', userEmail);

    return { token, restaurantId, name, phoneNumber, email: userEmail };
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Login failed');
  }
});

// Async thunk to fetch delivery addresses
// Async thunk to handle fetching delivery addresses
export const fetchDeliveryAddresses = createAsyncThunk(
    'auth/fetchDeliveryAddresses',
    async (restaurantId, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${apiUrl}/restaurant/${restaurantId}`);
        return response.data.deliveryAddresses;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Failed to fetch delivery addresses');
      }
    }
  );
  
  // Async thunk to handle adding a delivery address
  export const addDeliveryAddress = createAsyncThunk(
    'auth/addDeliveryAddress',
    async ({ restaurantId, address }, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${apiUrl}/restaurant/add-address`, {
          restaurantId,
          address,
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Failed to add address');
      }
    }
  );
  
  // Async thunk to handle editing a delivery address
  export const editDeliveryAddress = createAsyncThunk(
    'auth/editDeliveryAddress',
    async ({ restaurantId, addressId, newAddress }, { rejectWithValue }) => {
      try {
        const response = await axios.put(`${apiUrl}/restaurant/edit-address`, {
          restaurantId,
          addressId,
          newAddress,
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Failed to edit address');
      }
    }
  );
  
  // Async thunk to handle removing a delivery address
  export const removeDeliveryAddress = createAsyncThunk(
    'auth/removeDeliveryAddress',
    async ({ restaurantId, addressId }, { rejectWithValue }) => {
      try {
        const response = await axios.delete(`${apiUrl}/restaurant/remove-address`, {
          data: { restaurantId, addressId },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Failed to remove address');
      }
    }
  );

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: Cookies.get('token') || null,
    restaurantId: Cookies.get('restaurantId') || null,
    name: Cookies.get('name') || '',
    phoneNumber: Cookies.get('phoneNumber') || '',
    email: Cookies.get('email') || '',
    deliveryAddresses: [],
    status: 'idle',
    error: null,
    isLoggedIn: false,
    isLoading: true, // Initial state for loading validation status
  },
  reducers: {
    logout: (state) => {
      state.token = null;
      state.restaurantId = null;
      state.name = null;
      state.phoneNumber = null;
      state.email = null;
      state.deliveryAddresses = [];
      Cookies.remove('token');
      Cookies.remove('restaurantId');
      Cookies.remove('name');
      Cookies.remove('phoneNumber');
      Cookies.remove('email');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      }).addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload.token;
        state.restaurantId = action.payload.restaurantId;
        state.name = action.payload.name;
        state.phoneNumber = action.payload.phoneNumber;
        state.email = action.payload.email;
        state.isLoggedIn = true
      }).addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed'; // Set the status to failed on login failure
        state.error = action.payload || 'Login failed'; // Store the error message
      }).addCase(fetchDeliveryAddresses.fulfilled, (state, action) => {
        state.deliveryAddresses = action.payload;
      }).addCase(addDeliveryAddress.fulfilled, (state, action) => {
        state.deliveryAddresses = action.payload.deliveryAddresses;
        console.log(action.payload)
      }).addCase(editDeliveryAddress.fulfilled, (state, action) => {
        const index = state.deliveryAddresses.findIndex((address) => address._id === action.payload.updatedAddress._id);
        console.log(index)
        if (index !== -1) {
          state.deliveryAddresses[index] = action.payload.updatedAddress;
        }
        console.log(action.payload)
        .addCase(removeDeliveryAddress.fulfilled, (state, action) => {
          console.log(action.payload)
          state.deliveryAddresses = action.payload.deliveryAddresses;
        })
      }).addCase(validateToken.pending, (state) => {
        state.isLoading = true; // Set loading to true when validation starts
      }).addCase(validateToken.fulfilled, (state, action) => {
        state.isLoggedIn = action.payload.isValid;
        state.isLoading = false; // Set loading to false when validation fails
  }).addCase(validateToken.rejected, (state) => {
    state.isLoggedIn = false;
    state.user = null;
    state.isLoading = false; // Set loading to false when validation fails
  });

}});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
