import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOrders } from '../store/slices/orderSlice';
import './profile.css';
import Cookies from 'js-cookie';

const Profile = () => {
  const dispatch = useDispatch();
  const { regularOrders, recurringOrders } = useSelector((state) => state.order.userOrders);
  const status = useSelector((state) => state.order.status);
  const error = useSelector((state) => state.order.error);
  const restaurantId = Cookies.get('restaurantId');
  const [expandedOrderIds, setExpandedOrderIds] = useState({});
console.log(regularOrders)
  useEffect(() => {
    if (restaurantId) {
      dispatch(getUserOrders(restaurantId));
    }
  }, [dispatch, restaurantId]);

  const toggleOrderItems = (orderId) => {
    setExpandedOrderIds((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="profile-page">
      <h2 className="profile-header">User Orders</h2>

      <div className="orders-section">
        <h3 className="section-header">Regular Orders</h3>
        {regularOrders.length > 0 ? (
          regularOrders.map((order) => (
            <div key={order._id} className="order-item">
              <p><strong>Order Number:</strong> {order.orderNumber}</p>
              <p><strong>Order Date:</strong> {new Date(order.orderPlacedAt).toLocaleDateString()}</p>
              <p><strong>Total Price:</strong> QAR {order.totalPrice}</p>
              <p><strong>Status:</strong> {order.status}</p>

              <button 
                className="toggle-items-button" 
                onClick={() => toggleOrderItems(order._id)}
              >
                {expandedOrderIds[order._id] ? 'Hide Items' : 'View Items'}
              </button>

              {expandedOrderIds[order._id] && (
                <div className="order-items">
                  {order.items.map((item, index) => (
                    <div key={index} className="order-item-detail">
                      <p><strong>Name:</strong> {item.product.name} <strong>Type:</strong> {item.type}</p>
                      <p><strong>Quantity:</strong> {item.quantity}</p>
                      <p><strong>Price:</strong> QAR {item.price}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="no-orders">No regular orders found.</p>
        )}
      </div>

      <div className="orders-section">
        <h3 className="section-header">Recurring Orders</h3>
        {recurringOrders.length > 0 ? (
          recurringOrders.map((order) => (
            <div key={order._id} className="order-item recurring-order">
              <p><strong>Order Number:</strong> {order.orderNumber}</p>
              <p><strong>Order Date:</strong> {new Date(order.orderPlacedAt).toLocaleDateString()}</p>
              <p><strong>Total Price:</strong> QAR {order.totalPrice}</p>
              <p><strong>Status:</strong> {order.status}</p>
              <p><strong>Next Order Date:</strong> {new Date(order.nextOrderDate).toLocaleDateString()}</p>

              <button 
                className="toggle-items-button" 
                onClick={() => toggleOrderItems(order._id)}
              >
                {expandedOrderIds[order._id] ? 'Hide Items' : 'View Items'}
              </button>

              {expandedOrderIds[order._id] && (
                <div className="order-items">
                  {order.items.map((item, index) => (
                    <div key={index} className="order-item-detail">
                      <p><strong>Name:</strong> {item.product.name}</p>
                      <p><strong>Quantity:</strong> {item.quantity}</p>
                      <p><strong>Price:</strong> QAR {item.price}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="no-orders">No recurring orders found.</p>
        )}
      </div>
    </div>
  );
};

export default Profile;
