import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { fetchCart } from '../store/slices/cartSlice';
import { toast } from 'react-toastify';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status} = useSelector((state) => state.auth);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(loginUser({ email, password }))
      .unwrap()
      .then(() => {
        // Redirect to the dashboard upon successful login
        navigate('/products');
        dispatch(fetchCart());
      })
      .catch((err) => {
        toast.error('Incorrect Username or Password')
        console.error('Login failed:', err);
      });
  };
  
  return (
    <div className="login-page">
      <p className="login-notice">This content is protected. Please log in with your customer account to continue.</p>
  
      <div className="login-container">
        <h1 className="login-title">Login</h1>
  
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            required
          />
          <div className="login-links">
            <a href="#" className="forgot-password">Forgot your password?</a>
          </div>
          <button type="submit" className="login-button" disabled={status === 'loading'}>
            {status === 'loading' ? 'Signing in...' : 'Sign in'}
          </button>
        </form>
  
        <div className="create-account">
          <a href="#">Create account</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
