import './cartDropdown.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CartDropdown = ({ onClose }) => {
  const cartItems = useSelector((state) => state.cart.items);

  return (
    <div className="dropdown-container">
      <div className="dropdown-header">
        <span>Items added to your cart</span>
        <button className="dropdown-close-button" onClick={onClose}>X</button>
      </div>

      <div className="dropdown-items">
        {cartItems.length > 0 ? (
          cartItems.map((item) => (
            <div key={item._id} className="dropdown-item">
              <img src={item.product.media} alt={item.name} className="dropdown-item-image" />
              <div className="dropdown-item-details">
                <h4>{item.product.name}</h4>
                <p>Country: {item.country}</p>
                <p>Packaging: {item.packagingWeight}</p>
                <p>Type: {item.type}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="dropdown-empty">Your cart is empty</div>
        )}
      </div>

      <div className="dropdown-actions">
        <button className="dropdown-view-cart">
          <Link to="/cart" className="nav-link">
            View cart ({cartItems.length})
          </Link>
        </button>
      </div>
    </div>
  );
};

export default CartDropdown;
